import { getClient } from "./JWTApolloProvider";
import SCHEMA from "./schema";


const client = getClient();

export default class {
  constructor(query) {}

  destructEdgedResponse({ data }, key) {
    if (data !== undefined && key in data) {
      return data[key].edges.map(({ node }) => node);
    }
    return [];
  }
}

export const parseEdgeTypeResponse = ({ data }, key) => {
  if (data !== undefined && key in data) {
    return data[key].edges.map(({ node }) => node);
  }
  return [];
};

export const fetchCurrentUser = async (options = {}) => {
  let { data } = await client.query({
    query: SCHEMA.FETCH_CURRENT_USER,
    ...options,
  });
  return data;
};

export const updateCurrentLang = async lang => {
  let { data } = await client.mutate({
    mutation: SCHEMA.UPDATE_CURRENT_LANG,
    variables: {
      lang,
    },
  });
  return data;
};

export const fetchOptions = async () => {
  let { data } = await client.query({
    query: SCHEMA.FETCH_OPTIONS,
  });
  return data;
};
