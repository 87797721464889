import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { getAccessTokenSilently } from "hooks/useAuth";

export function getBaseUrl(path) {
  const url = process.env.REACT_APP_BACKEND_URL || "https://api.wchealthymemory.com";
  return `${url}${path}`;
}

interface IJWTApolloProviderProps {
  children: React.ReactNode;
}

export const getClient = () => {
  const httpLink: any = createUploadLink({
    uri: getBaseUrl("/graphql"),
  });
  const authLink: any = setContext(async () => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
  return client;
};

const JWTApolloProvider: React.FC<IJWTApolloProviderProps> = ({ children }) => {
  const client = getClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default JWTApolloProvider;
