import gql from "graphql-tag";

export const assignmentFragments = {
  assignment: gql`
    fragment Assignment on AssignmentNode {
      id
      title
      description
      internalNote
      uploadId
      upload {
        filename
        url
      }
      sortOrder
      courseId
      createdAt
    }
  `,
};

export const FETCH_ASSIGNMENTS = gql`
  query FETCH_ASSIGNMENTS($filters: AssignmentFilter) {
    getAssignments(filters: $filters, sort: SORT_ORDER_DESC) {
      edges {
        node {
          ...Assignment
        }
      }
    }
  }
  ${assignmentFragments.assignment}
`;

export const CREATE_ASSIGNMENT = gql`
  mutation CREATE_ASSIGNMENT($assignmentInput: AssignmentInput!) {
    createAssignment(assignmentInput: $assignmentInput) {
      assignment {
        ...Assignment
      }
    }
  }
  ${assignmentFragments.assignment}
`;

export const UPDATE_ASSIGNMENT = gql`
  mutation UPDATE_ASSIGNMENT(
    $assignmentId: ID!
    $assignmentInput: AssignmentInput!
  ) {
    updateAssignment(
      assignmentId: $assignmentId
      assignmentInput: $assignmentInput
    ) {
      assignment {
        ...Assignment
      }
    }
  }
  ${assignmentFragments.assignment}
`;

export const DELETE_ASSIGNMENT = gql`
  mutation DELETE_ASSIGNMENT($assignmentId: ID!) {
    deleteAssignment(assignmentId: $assignmentId) {
      isSuccess
    }
  }
`;

export const MOVE_ASSIGNMENT = gql`
  mutation MOVE_ASSIGNMENT($sourceId: ID!, $targetId: ID!) {
    moveAssignment(sourceId: $sourceId, targetId: $targetId) {
      isSuccess
    }
  }
`;
