import gql from "graphql-tag";

export const paymentFragments = {
  payment: gql`
    fragment Payment on PaymentNode {
      id
      amount
      taxes
      description
      kind
      status
      userId
    }
  `,
};

export const FETCH_PAYMENTS = gql`
  query FETCH_PAYMENTS($filters: PaymentFilter) {
    getPayments(filters: $filters) {
      edges {
        node {
          ...Payment
        }
      }
    }
  }
  ${paymentFragments.payment}
`;

// export const CREATE_PAYMENT = gql``;

// export const UPDATE_PAYMENT = gql``;

// export const DELETE_PAYMENT = gql``;
