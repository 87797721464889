import * as announcement from "./announcement";
import * as appConfig from "./app-config";
import * as assignment from "./assignment";
import * as course from "./course";
import * as dashboard from "./dashboard";
import * as forum from "./forum";
import * as option from "./option";
import * as payment from "./payment";
import * as quiz from "./quiz";
import * as room from "./room";
import * as scoreBook from "./score-books";
import * as session from "./session";
import * as subscription from "./subscription";
import * as user from "./user";

export default {
  ...announcement,
  ...appConfig,
  ...assignment,
  ...course,
  ...dashboard,
  ...forum,
  ...option,
  ...payment,
  ...quiz,
  ...room,
  ...scoreBook,
  ...session,
  ...subscription,
  ...user,
};
