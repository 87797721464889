import _ from "lodash";
import {
  LOGOUT_USER,
  SET_CURRENT_USER,
  SET_IS_AUTHENTICATED,
  SET_OPTIONS,
} from "./actions";

const INIT_STATE = {
  isAuthenticated: false,
  currentUser: {},
  userRole: "unauthenticated",
  option: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        isAuthenticated: true,
        userRole: _.get(
          action.payload,
          "role",
          "unauthenticated"
        ).toLowerCase(),
      };
    case LOGOUT_USER:
      return INIT_STATE;
    case SET_OPTIONS:
      let option = {};
      _.each(action.payload, ({ name, data, kind }) => {
        option[name] = parseData(kind, data);
      });
      return {
        ...state,
        option,
      };
    default:
      return state;
  }
};

function parseData(kind, data) {
  switch (kind.toUpperCase()) {
    case "FLOAT":
      return parseFloat(data);
    case "INTEGER":
      return parseInt(data);
    default:
      return data;
  }
}
