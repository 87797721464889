import { getClient } from "api/JWTApolloProvider";


export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_OPTIONS = "SET_OPTIONS";

export const setIsAuthenticated = isAuthenticated => {
  return {
    type: SET_IS_AUTHENTICATED,
    isAuthenticated,
  };
};

export const setCurrentUser = currentUser => {
  return {
    type: SET_CURRENT_USER,
    payload: currentUser,
  };
};

export const logoutUser = () => {
  const client = getClient();
  client.cache.reset();
  return { type: LOGOUT_USER };
};

export const setOptions = options => {
  return {
    type: SET_OPTIONS,
    payload: options,
  };
};
