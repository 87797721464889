import gql from "graphql-tag";

export const sessionFragments = {
  session: gql`
    fragment Session on SessionNode {
      id
      start
      end
      tzAwareStart
      tzAwareEnd
      description
      internalNote
      status
      courseId
      course {
        title
        timezone
      }
      instructorId
      instructor {
        fullName
      }
      clientId
      client {
        fullName
      }
    }
  `,
  detailSession: gql`
    fragment DetailSession on SessionNode {
      id
      start
      end
      tzAwareStart
      tzAwareEnd
      description
      internalNote
      instructorId
      status
      courseId
      course {
        title
        timezone
        participants {
          edges {
            node {
              id
              role
              fullName
            }
          }
        }
        quizGroups {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  `,
};

export const FETCH_SESSIONS = gql`
  query FETCH_SESSIONS($filters: SessionFilter) {
    getSessions(filters: $filters, sort: START_ASC) {
      edges {
        node {
          ...Session
        }
      }
    }
  }
  ${sessionFragments.session}
`;

export const FETCH_SESSION = gql`
  query FETCH_SESSION($sessionId: ID!) {
    getSession(sessionId: $sessionId) {
      ...DetailSession
    }
  }
  ${sessionFragments.detailSession}
`;

export const CREATE_SESSION = gql`
  mutation CREATE_SESSION($sessionInput: SessionInput!) {
    createSession(sessionInput: $sessionInput) {
      session {
        ...Session
      }
    }
  }
  ${sessionFragments.session}
`;

export const UPDATE_SESSION = gql`
  mutation UPDATE_SESSION($sessionId: ID!, $sessionInput: SessionInput!) {
    updateSession(sessionId: $sessionId, sessionInput: $sessionInput) {
      session {
        ...Session
      }
    }
  }
  ${sessionFragments.session}
`;

export const DELETE_SESSION = gql`
  mutation DELETE_SESSION($sessionId: ID!) {
    deleteSession(sessionId: $sessionId) {
      isSuccess
    }
  }
`;

export const RESERVE_SESSION = gql`
  mutation RESERVE_SESSION($sessionId: ID!, $userId: ID) {
    createSessionParticipant(sessionId: $sessionId, userId: $userId) {
      isSuccess
    }
  }
`;

export const CANCEL_SESSION = gql`
  mutation CANCEL_SESSION($sessionId: ID!, $userId: ID) {
    deleteSessionParticipant(sessionId: $sessionId, userId: $userId) {
      isSuccess
    }
  }
`;

export const FETCH_SESSION_AVAILABILITIES = gql`
  query FETCH_SESSION_AVAILABILITIES($filters: SessionAvailabilityFilter) {
    getSessionAvailabilities(filters: $filters, sort: START_ASC) {
      edges {
        node {
          start
          status
        }
      }
    }
  }
`;

export const CREATE_SESSION_AVAILABILITIES = gql`
  mutation CREATE_SESSION_AVAILABILITIES(
    $sessionAvailabilityInputs: [SessionAvailabilityInput!]!
  ) {
    createSessionAvailabilities(
      sessionAvailabilityInputs: $sessionAvailabilityInputs
    ) {
      isSuccess
    }
  }
`;

export const UPDATE_SESSION_AVAILABILITY = gql`
  mutation UPDATE_SESSION_AVAILABILITY(
    $sessionAvailabilityId: ID!
    $sessionAvailabilityInput: SessionAvailabilityInput!
  ) {
    updateSessionAvailabilities(
      sessionAvailabilityId: $sessionAvailabilityId
      sessionAvailabilityInput: $sessionAvailabilityInput
    ) {
      isSuccess
    }
  }
`;

export const DELETE_SESSION_AVAILABILITY = gql`
  mutation DELETE_SESSION_AVAILABILITY($sessionAvailabilityId: ID!) {
    deleteSessionAvailability(sessionAvailabilityId: $sessionAvailabilityId) {
      isSuccess
    }
  }
`;

export const BOOK_INDIVIDUAL_SESSION = gql`
  mutation BOOK_INDIVIDUAL_SESSION(
    $courseId: ID!
    $clientId: ID
    $start: DateTime!
    $end: DateTime!
  ) {
    bookIndividualSession(
      courseId: $courseId
      clientId: $clientId
      start: $start
      end: $end
    ) {
      isSuccess
    }
  }
`;
