import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "https://api.wchealthymemory.com";
const ACCESS_TOKEN_EXPIRY = 86400;

export class TokenManager {
  setAccessToken(_accessToken: string) {
    localStorage.setItem("accessToken", _accessToken);
    localStorage.setItem(
      "tokenExpiration",
      String(moment().unix() + ACCESS_TOKEN_EXPIRY)
    );
  }

  getAccessToken(): string | null {
    return localStorage.getItem("accessToken");
  }

  setRefreshToken(_refreshToken: string) {
    localStorage.setItem("refreshToken", _refreshToken);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem("refreshToken");
  }

  isExpired(): boolean {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (tokenExpiration) {
      return moment().unix() < parseInt(tokenExpiration);
    }
    return false;
  }

  clear() {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  }
}

export async function login(email: string, password: string) {
  const tokenManager = new TokenManager();
  const response = await axios.post(`${baseUrl}/auth/login`, {
    email,
    password,
  });
  tokenManager.setAccessToken(response.data["access_token"]);
  tokenManager.setRefreshToken(response.data["refresh_token"]);
}

export async function refreshAccessToken(
  refreshToken: string
): Promise<string | null> {
  try {
    const response = await axios.post(`${baseUrl}/auth/refresh`, {
      refresh_token: refreshToken,
    });
    return response.data["access_token"];
  } catch {
    return null;
  }
}

export const getAccessTokenSilently = async (): Promise<string | null> => {
  try {
    const tokenManager = new TokenManager();
    const accessToken = tokenManager.getAccessToken();
    const refreshToken = tokenManager.getRefreshToken();
    if (accessToken && refreshToken) {
      if (tokenManager.isExpired()) {
        return await refreshAccessToken(refreshToken);
      }
      return accessToken;
    }
    return null;
  } catch {
    return null;
  }
};

interface IUseAuthResponse {
  isAuthenticated: boolean;
  logout: () => void;
}

export const useAuth = (): IUseAuthResponse => {
  const history = useHistory();
  const tokenManager = new TokenManager();
  const accessToken = tokenManager.getAccessToken();
  return {
    isAuthenticated: accessToken !== null,
    logout: () => {
      tokenManager.clear();
      history.push("/login");
    },
  };
};
