import gql from "graphql-tag";

export const forumFragments = {
  forum: gql`
    fragment Forum on ForumNode {
      id
      title
      description
      status
      kind
      ownerId
      owner {
        fullName
      }
      sortOrder
    }
  `,
  detailForum: gql`
    fragment Forum on ForumNode {
      id
      title
      description
      status
      kind
      ownerId
      owner {
        fullName
      }
      messages {
        edges {
          node {
            id
            content
            status
            owner {
              fullName
            }
            createdAt
          }
        }
      }
      sortOrder
    }
  `,
  message: gql`
    fragment Message on MessageNode {
      id
      content
      status
      ownerId
      owner {
        fullName
      }
      createdAt
    }
  `,
};

export const FETCH_FORUMS = gql`
  query FETCH_FORUMS($filters: ForumFilter) {
    getForums(filters: $filters) {
      edges {
        node {
          ...Forum
        }
      }
    }
  }
  ${forumFragments.forum}
`;

export const FETCH_FORUM = gql`
  query FETCH_FORUM($forumId: ID!) {
    getForum(forumId: $forumId) {
      ...Forum
    }
  }
  ${forumFragments.detailForum}
`;

export const CREATE_FORUM = gql`
  mutation CREATE_FORUM($forumInput: ForumInput!) {
    createForum(forumInput: $forumInput) {
      forum {
        ...Forum
      }
    }
  }
  ${forumFragments.forum}
`;

export const UPDATE_FORUM = gql`
  mutation UPDATE_FORUM($forumId: ID!, $forumInput: ForumInput!) {
    updateForum(forumId: $forumId, forumInput: $forumInput) {
      forum {
        ...Forum
      }
    }
  }
  ${forumFragments.forum}
`;

export const DELETE_FORUM = gql`
  mutation DELETE_FORUM($forumId: ID!) {
    deleteForum(forumId: $forumId) {
      isSuccess
    }
  }
`;

export const FETCH_MESSAGES = gql`
  query FETCH_MESSAGES($filters: MessageFilter, $first: Int, $after: String) {
    getMessages(
      filters: $filters
      first: $first
      after: $after
      sort: CREATED_AT_DESC
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          ...Message
        }
      }
    }
  }
  ${forumFragments.message}
`;

export const CREATE_MESSAGE = gql`
  mutation CREATE_MESSAGE($messageInput: MessageInput!) {
    createMessage(messageInput: $messageInput) {
      message {
        ...Message
      }
    }
  }
  ${forumFragments.message}
`;

export const UPDATE_MESSAGE = gql`
  mutation UPDATE_MESSAGE($messageId: ID!, $messageInput: MessageInput!) {
    updateMessage(messageId: $messageId, messageInput: $messageInput) {
      message {
        ...Message
      }
    }
  }
  ${forumFragments.message}
`;

export const DELETE_MESSAGE = gql`
  mutation DELETE_MESSAGE($messageId: ID!) {
    deleteMessage(messageId: $messageId) {
      isSuccess
    }
  }
`;
