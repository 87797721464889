import gql from "graphql-tag";

export const scoreBookFragments = {
  scoreBook: gql`
    fragment ScoreBook on ScoreBookNode {
      id
      time
      strTime
      comment
      material
      internalNote
      method
      userId
      user {
        fullName
      }
      instructorId
      instructor {
        fullName
      }
      sessionId
      createdAt
    }
  `,
};

export const FETCH_SCORE_BOOKS = gql`
  query FETCH_SCORE_BOOKS($filters: ScoreBookFilter) {
    getScoreBooks(filters: $filters) {
      edges {
        node {
          ...ScoreBook
        }
      }
    }
  }
  ${scoreBookFragments.scoreBook}
`;

export const FETCH_CLIENT_SCORE_BOOKS = gql`
  query FETCH_CLIENT_SCORE_BOOKS($userId: ID!) {
    numberBoard: getScoreBooks(
      filters: { userId: $userId, method: "number_board" }
    ) {
      edges {
        node {
          time
          createdAt
        }
      }
    }
    numberBoardBack: getScoreBooks(
      filters: { userId: $userId, method: "number_board_back" }
    ) {
      edges {
        node {
          time
          createdAt
        }
      }
    }
    tongueTwister: getScoreBooks(
      filters: { userId: $userId, method: "tongue_twister" }
    ) {
      edges {
        node {
          time
          createdAt
        }
      }
    }
    readingAloud: getScoreBooks(
      filters: { userId: $userId, method: "reading_aloud" }
    ) {
      edges {
        node {
          time
          createdAt
        }
      }
    }
    mathematics: getScoreBooks(
      filters: { userId: $userId, method: "mathematics" }
    ) {
      edges {
        node {
          time
          createdAt
        }
      }
    }
    misc: getScoreBooks(filters: { userId: $userId, method: "misc" }) {
      edges {
        node {
          time
          createdAt
        }
      }
    }
  }
`;

export const CREATE_SCORE_BOOK = gql`
  mutation CREATE_SCORE_BOOK($scoreBookInput: ScoreBookInput!) {
    createScoreBook(scoreBookInput: $scoreBookInput) {
      scoreBook {
        ...ScoreBook
      }
    }
  }
  ${scoreBookFragments.scoreBook}
`;

export const UPDATE_SCORE_BOOK = gql`
  mutation UPDATE_SCORE_BOOK(
    $scoreBookId: ID!
    $scoreBookInput: ScoreBookInput!
  ) {
    updateScoreBook(
      scoreBookId: $scoreBookId
      scoreBookInput: $scoreBookInput
    ) {
      scoreBook {
        ...ScoreBook
      }
    }
  }
  ${scoreBookFragments.scoreBook}
`;

export const DELETE_SCORE_BOOK = gql`
  mutation DELETE_SCORE_BOOK($scoreBookId: ID!) {
    deleteScoreBook(scoreBookId: $scoreBookId) {
      isSuccess
    }
  }
`;
