import gql from "graphql-tag";

export const appConfigFragments = {
  appConfig: gql`
    fragment AppConfig on AppConfigNode {
      key
      value
    }
  `,
};

export const FETCH_APP_CONFIGS = gql`
  query FETCH_APP_CONFIGS {
    getAppConfigs {
      edges {
        node {
          ...AppConfig
        }
      }
    }
  }
  ${appConfigFragments.appConfig}
`;

export const UPDATE_APP_CONFIG = gql`
  mutation UPDATE_APP_CONFIG($appConfigInput: AppConfigInput!) {
    updateAppConfig(appConfigInput: $appConfigInput) {
      isSuccess
    }
  }
`;

export const DELETE_APP_CONFIG = gql`
  mutation DELETE_APP_CONFIG($appConfigInput: AppConfigInput!) {
    deleteAppConfig(appConfigInput: $appConfigInput) {
      isSuccess
    }
  }
`;
