import gql from "graphql-tag";
import { optionFragments } from "./option";

export const userFragments = {
  user: gql`
    fragment User on UserNode {
      id
      email
      firstName
      lastName
      fullName
      birthdate
      gender
      building
      street
      city
      province
      country
      postalCode
      timezone
      lang
      role
      status
    }
  `,
  detailUser: gql`
    fragment DetailUser on UserNode {
      id
      email
      firstName
      lastName
      fullName
      birthdate
      gender
      building
      street
      city
      province
      country
      postalCode
      timezone
      lang
      role
      status
      forumId
      nextSession {
        tzAwareStart
        course {
          room {
            url
            medium
          }
        }
      }
    }
  `,
};

export const FETCH_USERS = gql`
  query FETCH_USERS($filters: UserFilter) {
    getUsers(filters: $filters) {
      edges {
        node {
          ...User
        }
      }
    }
  }
  ${userFragments.user}
`;

export const FETCH_USER = gql`
  query FETCH_USER($userId: ID!) {
    getUser(userId: $userId) {
      ...DetailUser
    }
  }
  ${userFragments.detailUser}
`;

export const FETCH_CURRENT_USER = gql`
  query FETCH_CURRENT_USER {
    getCurrentUser {
      ...DetailUser
    }
  }
  ${userFragments.detailUser}
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      user {
        ...DetailUser
        accessToken
        refreshToken
      }
    }
  }
  ${userFragments.detailUser}
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($userId: ID!, $userInput: UserInput!) {
    updateUser(userId: $userId, userInput: $userInput) {
      user {
        ...DetailUser
      }
    }
  }
  ${userFragments.detailUser}
`;

export const UPDATE_CURRENT_LANG = gql`
  mutation UPDATE_CURRENT_LANG($lang: String!) {
    updateCurrentLang(lang: $lang) {
      isSuccess
    }
  }
`;
