import gql from "graphql-tag";

export const optionFragments = {
  option: gql`
    fragment Option on OptionNode {
      id
      group
      kind
      name
      data
      createdAt
      updatedAt
    }
  `,
};

export const FETCH_OPTIONS = gql`
  query FETCH_OPTIONS {
    getOptions(sort: GROUP_ASC) {
      edges {
        node {
          ...Option
        }
      }
    }
  }
  ${optionFragments.option}
`;

export const CREATE_OPTION = gql`
  mutation CREATE_OPTION($optionInput: OptionInput!) {
    createOption(optionInput: $optionInput) {
      option {
        ...Option
      }
    }
  }
  ${optionFragments.option}
`;

export const UPDATE_OPTION = gql`
  mutation UPDATE_OPTION($optionId: ID!, $optionInput: OptionInput!) {
    updateOption(optionId: $optionId, optionInput: $optionInput) {
      option {
        ...Option
      }
    }
  }
  ${optionFragments.option}
`;

export const DELETE_OPTION = gql`
  mutation DELETE_OPTION($optionId: ID!) {
    deleteOption(optionId: $optionId) {
      isSuccess
    }
  }
`;
