import gql from "graphql-tag";

export const roomFragments = {
  room: gql`
    fragment Room on RoomNode {
      id
      name
      capacity
      description
      internalNote
      url
      medium
      status
    }
  `,
};

export const FETCH_ROOMS = gql`
  query FETCH_ROOMS($filters: RoomFilter) {
    getRooms(filters: $filters) {
      edges {
        node {
          ...Room
        }
      }
    }
  }
  ${roomFragments.room}
`;

export const CREATE_ROOM = gql`
  mutation CREATE_ROOM($roomInput: RoomInput!) {
    createRoom(roomInput: $roomInput) {
      room {
        ...Room
      }
    }
  }
  ${roomFragments.room}
`;

export const UPDATE_ROOM = gql`
  mutation UPDATE_ROOM($roomId: ID!, $roomInput: RoomInput!) {
    updateRoom(roomId: $roomId, roomInput: $roomInput) {
      room {
        ...Room
      }
    }
  }
  ${roomFragments.room}
`;

export const DELETE_ROOM = gql`
  mutation DELETE_ROOM($roomId: ID!) {
    deleteRoom(roomId: $roomId) {
      isSuccess
    }
  }
`;
