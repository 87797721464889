import gql from "graphql-tag";

export const quizFragments = {
  quiz: gql`
    fragment Quiz on QuizNode {
      id
      question
      answer
      description
      internalNote
      score
      kind
      status
      groupId
      sortOrder
    }
  `,
  detailQuiz: gql`
    fragment DetailQuiz on QuizNode {
      id
    }
  `,
  quizGroup: gql`
    fragment QuizGroup on QuizGroupNode {
      id
      title
      description
      internalNote
      status
    }
  `,
  detailQuizGroup: gql`
    fragment DetailQuizGroup on QuizGroupNode {
      id
      title
      description
      internalNote
      status
      quizzes {
        edges {
          node {
            id
            question
            answer
            description
            internalNote
            score
            kind
            status
            sortOrder
          }
        }
      }
    }
  `,
  quizTaker: gql`
    fragment QuizTaker on QuizSummaryNode {
      id
      user {
        id
        fullName
      }
      completedQuizGroupIds
    }
  `,
};

export const FETCH_QUIZZES = gql`
  query FETCH_QUIZZES($filters: QuizFilter) {
    getQuizzes(filters: $filters) {
      edges {
        node {
          ...Quiz
        }
      }
    }
  }
  ${quizFragments.quiz}
`;

export const FETCH_QUIZ = gql`
  query FETCH_QUIZ($quizId: ID!) {
    getQuiz(quizId: $quizId) {
      ...DetailQuiz
    }
  }
  ${quizFragments.detailQuiz}
`;

export const CREATE_QUIZ = gql`
  mutation CREATE_QUIZ($quizInput: QuizInput!) {
    createQuiz(quizInput: $quizInput) {
      quiz {
        ...DetailQuiz
      }
    }
  }
  ${quizFragments.detailQuiz}
`;

export const UPDATE_QUIZ = gql`
  mutation UPDATE_QUIZ($quizId: ID!, $quizInput: QuizInput!) {
    updateQuiz(quizId: $quizId, quizInput: $quizInput) {
      quiz {
        ...DetailQuiz
      }
    }
  }
  ${quizFragments.detailQuiz}
`;

export const DELETE_QUIZ = gql`
  mutation DELETE_QUIZ($quizId: ID!) {
    deleteQuiz(quizId: $quizId) {
      isSuccess
    }
  }
`;

export const FETCH_QUIZ_GROUPS = gql`
  query FETCH_QUIZ_GROUPS($filters: QuizGroupFilter) {
    getQuizGroups(filters: $filters) {
      edges {
        node {
          ...QuizGroup
        }
      }
    }
  }
  ${quizFragments.quizGroup}
`;

export const FETCH_QUIZ_GROUP = gql`
  query FETCH_QUIZ_GROUP($quizGroupId: ID!) {
    getQuizGroup(quizGroupId: $quizGroupId) {
      ...DetailQuizGroup
    }
  }
  ${quizFragments.detailQuizGroup}
`;

export const CREATE_QUIZ_GROUP = gql`
  mutation CREATE_QUIZ_GROUP($quizGroupInput: QuizGroupInput!) {
    createQuizGroup(quizGroupInput: $quizGroupInput) {
      quizGroup {
        ...QuizGroup
      }
    }
  }
  ${quizFragments.quizGroup}
`;

export const UPDATE_QUIZ_GROUP = gql`
  mutation UPDATE_QUIZ_GROUP(
    $quizGroupId: ID!
    $quizGroupInput: QuizGroupInput!
  ) {
    updateQuizGroup(
      quizGroupId: $quizGroupId
      quizGroupInput: $quizGroupInput
    ) {
      quizGroup {
        ...QuizGroup
      }
    }
  }
  ${quizFragments.quizGroup}
`;

export const DELETE_QUIZ_GROUP = gql`
  mutation DELETE_QUIZ_GROUP($quizGroupId: ID!) {
    deleteQuizGroup(quizGroupId: $quizGroupId) {
      isSuccess
    }
  }
  ${quizFragments.quizGroup}
`;

// export const FETCH_QUIZ_SUMMARIES = gql``;

// export const CREATE_QUIZ_SUMMARY = gql``;

// export const UPDATE_QUIZ_SUMMARY = gql``;

// export const DELETE_QUIZ_SUMMARY = gql``;

export const FETCH_QUIZ_TAKERS = gql`
  query FETCH_QUIZ_TAKERS($sessionId: ID!) {
    getQuizTakers(sessionId: $sessionId) {
      edges {
        node {
          ...QuizTaker
        }
      }
    }
  }
  ${quizFragments.quizTaker}
`;

export const CREATE_QUIZ_SCORES = gql`
  mutation CREATE_QUIZ_SCORES($quizScoreInputs: [QuizScoreInput!]!) {
    createQuizScores(quizScoreInputs: $quizScoreInputs) {
      quizScores {
        id
      }
    }
  }
`;
