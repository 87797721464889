import gql from "graphql-tag";

const fragments = {
  announcement: gql`
    fragment Announcement on AnnouncementNode {
      id
      content
      start
      end
      lang
      kind
      status
      target
      sortOrder
    }
  `,
};

export const FETCH_ANNOUNCEMENTS = gql`
  query FETCH_ANNOUNCEMENTS($filters: AnnouncementFilter) {
    getAnnouncements(filters: $filters) {
      edges {
        node {
          ...Announcement
        }
      }
    }
  }
  ${fragments.announcement}
`;

export const FETCH_CURRENT_ANNOUNCEMENTS = gql`
  query FETCH_CURRENT_ANNOUNCEMENTS {
    getCurrentAnnouncements {
      ...Announcement
    }
  }
  ${fragments.announcement}
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation CREATE_ANNOUNCEMENT($announcementInput: AnnouncementInput!) {
    createAnnouncement(announcementInput: $announcementInput) {
      announcement {
        ...Announcement
      }
    }
  }
  ${fragments.announcement}
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UPDATE_ANNOUNCEMENT(
    $announcementId: ID!
    $announcementInput: AnnouncementInput!
  ) {
    updateAnnouncement(
      announcementId: $announcementId
      announcementInput: $announcementInput
    ) {
      announcement {
        ...Announcement
      }
    }
  }
  ${fragments.announcement}
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation DELETE_ANNOUNCEMENT($announcementId: ID!) {
    deleteAnnouncement(announcementId: $announcementId) {
      isSuccess
    }
  }
`;
