import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { configureStore } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import JWTApolloProvider from "api/JWTApolloProvider";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));
export const store = configureStore();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<div className="loading" />}>
        <JWTApolloProvider>
          <App />
        </JWTApolloProvider>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
