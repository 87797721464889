import gql from "graphql-tag";

export const subscriptionFragments = {
  subscription: gql`
    fragment Subscription on SubscriptionNode {
      id
      name
      price
      taxes
      taxGst
      taxPst
      expiryDate
      period
      kind
      total
      status
      payment
      isPaid
      autoRenewal
      userId
      passKind
      courses {
        edges {
          node {
            id
            title
          }
        }
      }
      user {
        fullName
      }
      createdAt
      updatedAt
    }
  `,
};

export const FETCH_SUBSCRIPTIONS = gql`
  query FETCH_SUBSCRIPTIONS($filters: SubscriptionFilter) {
    getSubscriptions(filters: $filters, sort: CREATED_AT_DESC) {
      edges {
        node {
          ...Subscription
        }
      }
    }
  }
  ${subscriptionFragments.subscription}
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CREATE_SUBSCRIPTION($subscriptionInput: SubscriptionInput!) {
    createSubscription(subscriptionInput: $subscriptionInput) {
      subscription {
        ...Subscription
      }
    }
  }
  ${subscriptionFragments.subscription}
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UPDATE_SUBSCRIPTION(
    $subscriptionId: ID!
    $subscriptionInput: SubscriptionInput!
  ) {
    updateSubscription(
      subscriptionId: $subscriptionId
      subscriptionInput: $subscriptionInput
    ) {
      subscription {
        ...Subscription
      }
    }
  }
  ${subscriptionFragments.subscription}
`;

export const DELETE_SUBSCRIPTION = gql`
  mutation DELETE_SUBSCRIPTION($subscriptionId: ID!) {
    deleteSubscription(subscriptionId: $subscriptionId) {
      isSuccess
    }
  }
`;

export const APPROVE_SUBSCRIPTION = gql`
  mutation APPROVE_SUBSCRIPTION($subscriptionId: ID!) {
    approveSubscription(subscriptionId: $subscriptionId) {
      isSuccess
    }
  }
`;
