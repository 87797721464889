import gql from "graphql-tag";
import { sessionFragments } from "./session";

export const courseFragments = {
  course: gql`
    fragment Course on CourseNode {
      id
      title
      description
      start
      end
      deadline
      kind
      status
      coverImage
      isFull
      isParticipant
      forumId
      roomId
      schedules {
        edges {
          node {
            id
            start
            end
            dayOfWeek
          }
        }
      }
      room {
        name
        description
        medium
        url
      }
      lang
      categoryId
      category {
        id
        title
      }
    }
  `,
  detailCourse: gql`
    fragment DetailCourse on CourseNode {
      id
      title
      description
      start
      end
      deadline
      kind
      status
      coverImage
      isFull
      isParticipant
      forumId
      roomId
      room {
        name
        description
        medium
        url
      }
      lang
      participants {
        edges {
          node {
            id
            role
            fullName
            city
            province
          }
        }
      }
      categoryId
      category {
        id
        title
      }
      quizGroups {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
  onboardingCourse: gql`
    fragment OnboardingCourse on CourseNode {
      id
      title
      description
      start
      end
      deadline
      kind
      status
      coverImage
      isFull
      isParticipant
      forumId
      roomId
      room {
        name
        description
        medium
        url
      }
      lang
      participants {
        edges {
          node {
            id
            fullName
            city
            province
          }
        }
      }
      categoryId
      category {
        id
        title
      }
      quizGroups {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
  courseCategory: gql`
    fragment CourseCategory on CourseCategoryNode {
      id
      title
      activeCourses {
        id
        title
        description
        coverImage
        lang
        status
        room {
          name
          medium
          url
        }
      }
    }
  `,
  courseSchedule: gql`
    fragment CourseSchedule on CourseScheduleNode {
      id
      dayOfWeek
      start
      end
      internalNote
      kind
      instructor {
        id
        fullName
      }
      sortOrder
    }
  `,
  coursePricing: gql`
    fragment CoursePricing on CoursePricingNode {
      id
      price
      kind
      status
      courseId
      course {
        title
      }
    }
  `,
};

export const FETCH_COURSES = gql`
  query FETCH_COURSES($filters: CourseFilter) {
    getCourses(filters: $filters) {
      edges {
        node {
          ...Course
        }
      }
    }
  }
  ${courseFragments.course}
`;

export const FETCH_COURSE = gql`
  query FETCH_COURSE($courseId: ID!) {
    getCourse(courseId: $courseId) {
      ...DetailCourse
    }
  }
  ${courseFragments.detailCourse}
`;

export const FETCH_COURSE_WITH_SESSIONS = gql`
  query FETCH_COURSE_WITH_SESSIONS($courseId: ID!, $isUpcoming: Boolean) {
    getCourse(courseId: $courseId) {
      ...DetailCourse
    }
    getSessions(
      filters: { courseId: $courseId, isUpcoming: $isUpcoming }
      sort: START_ASC
    ) {
      edges {
        node {
          ...Session
        }
      }
    }
  }
  ${courseFragments.detailCourse}
  ${sessionFragments.session}
`;

export const CREATE_COURSE = gql`
  mutation CREATE_COURSE($courseInput: CourseInput!) {
    createCourse(courseInput: $courseInput) {
      course {
        ...Course
      }
    }
  }
  ${courseFragments.course}
`;

export const UPDATE_COURSE = gql`
  mutation UPDATE_COURSE($courseId: ID!, $courseInput: CourseInput!) {
    updateCourse(courseId: $courseId, courseInput: $courseInput) {
      course {
        ...Course
      }
    }
  }
  ${courseFragments.course}
`;

export const DELETE_COURSE = gql`
  mutation DELETE_COURSE($courseId: ID!) {
    deleteCourse(courseId: $courseId) {
      isSuccess
    }
  }
`;

export const FETCH_COURSE_CATEGORIES = gql`
  query FETCH_COURSE_CATEGORIES($filters: CourseCategoryFilter) {
    getCourseCategories(filters: $filters) {
      edges {
        node {
          ...CourseCategory
        }
      }
    }
  }
  ${courseFragments.courseCategory}
`;

export const CREATE_COURSE_CATEGORY = gql`
  mutation CREATE_COURSE_CATEGORY($courseCategoryInput: CourseCategoryInput!) {
    createCourseCategory(courseCategoryInput: $courseCategoryInput) {
      courseCategory {
        ...CourseCategory
      }
    }
  }
  ${courseFragments.courseCategory}
`;

export const UPDATE_COURSE_CATEGORY = gql`
  mutation UPDATE_COURSE_CATEGORY(
    $courseCategoryId: ID!
    $courseCategoryInput: CourseCategoryInput!
  ) {
    updateCourseCategory(
      courseCategoryId: $courseCategoryId
      courseCategoryInput: $courseCategoryInput
    ) {
      courseCategory {
        ...CourseCategory
      }
    }
  }
  ${courseFragments.courseCategory}
`;

export const DELETE_COURSE_CATEGORY = gql`
  mutation DELETE_COURSE_CATEGORY($courseCategoryId: ID!) {
    deleteCourseCategory(courseCategoryId: $courseCategoryId) {
      isSuccess
    }
  }
`;

export const FETCH_COURSE_SCHEDULES = gql`
  query FETCH_COURSE_SCHEDULES($filters: CourseScheduleFilter) {
    getCourseSchedules(filters: $filters) {
      edges {
        node {
          ...CourseSchedule
        }
      }
    }
  }
  ${courseFragments.courseSchedule}
`;

export const CREATE_COURSE_SCHEDULE = gql`
  mutation CREATE_COURSE_SCHEDULE($courseScheduleInput: CourseScheduleInput!) {
    createCourseSchedule(courseScheduleInput: $courseScheduleInput) {
      courseSchedule {
        ...CourseSchedule
      }
    }
  }
  ${courseFragments.courseSchedule}
`;

export const UPDATE_COURSE_SCHEDULE = gql`
  mutation UPDATE_COURSE_SCHEDULE(
    $courseScheduleId: ID!
    $courseScheduleInput: CourseScheduleInput!
  ) {
    updateCourseSchedule(
      courseScheduleId: $courseScheduleId
      courseScheduleInput: $courseScheduleInput
    ) {
      courseSchedule {
        ...CourseSchedule
      }
    }
  }
  ${courseFragments.courseSchedule}
`;

export const DELETE_COURSE_SCHEDULE = gql`
  mutation DELETE_COURSE_SCHEDULE($courseScheduleId: ID!) {
    deleteCourseSchedule(courseScheduleId: $courseScheduleId) {
      isSuccess
    }
  }
`;

export const JOIN_COURSE = gql`
  mutation JOIN_COURSE($courseId: ID!, $userId: ID) {
    joinCourse(courseId: $courseId, userId: $userId) {
      isSuccess
    }
  }
`;

export const LEAVE_COURSE = gql`
  mutation LEAVE_COURSE($courseId: ID!, $userId: ID) {
    leaveCourse(courseId: $courseId, userId: $userId) {
      isSuccess
    }
  }
`;

export const UPDATE_COURSE_PARTICIPANT = gql`
  mutation UPDATE_COURSE_PARTICIPANT(
    $userId: ID
    $courseId: ID!
    $status: String!
  ) {
    updateCourseParticipant(
      userId: $userId
      courseId: $courseId
      status: $status
    ) {
      isSuccess
    }
  }
`;

export const CREATE_COURSE_QUIZ_GROUP = gql`
  mutation CREATE_COURSE_QUIZ_GROUP($courseId: ID!, $quizGroupId: ID!) {
    createCourseQuizGroup(courseId: $courseId, quizGroupId: $quizGroupId) {
      isSuccess
    }
  }
`;

export const DELETE_COURSE_QUIZ_GROUP = gql`
  mutation DELETE_COURSE_QUIZ_GROUP($courseId: ID!, $quizGroupId: ID!) {
    deleteCourseQuizGroup(courseId: $courseId, quizGroupId: $quizGroupId) {
      isSuccess
    }
  }
`;
