import { CHANGE_LOCALE } from "../actions";
import { updateCurrentLang } from "api/utils";

export const changeLocale = locale => {
  localStorage.setItem("currentLanguage", locale);
  updateCurrentLang(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};
