import gql from "graphql-tag";
import { courseFragments } from "./course";
import { sessionFragments } from "./session";

export const FETCH_CLIENT_DASHBOARD = gql`
  query FETCH_CLIENT_DASHBOARD {
    getCourseCategories {
      edges {
        node {
          ...CourseCategory
        }
      }
    }
    getUpcomingSessions(sort: START_ASC) {
      edges {
        node {
          ...Session
        }
      }
    }
  }
  ${courseFragments.courseCategory}
  ${sessionFragments.session}
`;
